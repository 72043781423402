<template>
    <div class="datepicker">
        <date-range-picker
            ref="picker"
            v-model="dateRange"
            :autoApply="true"
            :linkedCalendars="true"
            :locale-data="{
                direction: 'ltr',
                format: 'yyyy/mm/dd',
                separator: ' - ',
                applyLabel: 'Apply',
                cancelLabel: 'Cancel',
                weekLabel: 'W',
                customRangeLabel: 'Custom Range',
                daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                monthNames: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
                firstDay: 0
            }"
            :maxDate="maxDate"
            :opens="position"
            :ranges="false"
            :showDropdowns="true"
            :showWeekNumbers="true"
            :timePicker24Hour="false"
            @update="resetDate"
            @change-month="limitDateRange">
            <template v-slot:input="picker">
                <span class="text-center">
                    <span>{{ picker.startDate | date }}</span>
                    <span class="separator">〜</span>
                    <span>{{ picker.endDate | date }}</span>
                </span>
            </template>
        </date-range-picker>
    </div>
</template>

<script>
import {app} from '../../constants'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
    name: "DatePicker",
    components: {
        DateRangePicker
    },
    props: {
        position: {type: String, default: 'left'}
    },
    data() {
        return {
            app: app,
            timePicker: true,
            maxDate: '',
            dateRange: {
                startDate: null,
                endDate: null
            }
        }
    },
    mounted() {
        // $('.datepicker').css('marginLeft','-10px')
        // $('.vue-daterange-picker').children().removeClass('form-control');
        $('.reportrange-text').css({
                'minWidth': '234px'
            })
        // )
    },
    filters: {
        date(val) {
            return val ? moment(val).format("YYYY/MM/DD") : ""
        }
    },
    methods: {
        setProperties(name, data) {
            localStorage.setItem(this.app.alias + '-' + name, JSON.stringify(data))
        },
        getProperties(name) {
            return JSON.parse(localStorage.getItem(this.app.alias + '-' + name))
        },
        resetDate(sendEmit = true) {
            if (!this.timePicker) {
                this.dateRange.startDate = this.getDateFormat(moment(this.dateRange.startDate).startOf('day'))
                this.dateRange.endDate = this.getDateFormat(moment(this.dateRange.endDate).endOf('day'))
            }

            this.setProperties('dateRange', this.dateRange)
            if (sendEmit) {
                this.$emit('change-date', this.dateRange)
            }
        },
        setLatest14DaysAsDefaultSelection(sendEmit = true) {
            if (!this.dateRange.startDate && !this.dateRange.endDate) {
                this.dateRange.startDate = this.getDateFormat(moment().subtract(7, 'days').second(0))
                this.dateRange.endDate = this.getDateFormat(moment().add(7, 'days').second(0))
            }
            if (sendEmit) {
                this.$emit('change-date', this.dateRange)
            }
        },
        setTodayAsDefaultSelection(sendEmit = true) {
            if (!this.dateRange.startDate && !this.dateRange.endDate) {
                this.dateRange.startDate = this.getDateFormat(moment(new Date))
                this.dateRange.endDate = this.getDateFormat(moment(new Date))
            }
            if (sendEmit) {
                this.$emit('change-date', this.dateRange)
            }
        },
        setDateRange(dateRange, sendEmit = true) {
            this.dateRange = dateRange
            if (sendEmit) {
                this.$emit('change-date', this.dateRange)
            }
        },
        limitDateRange() {
            const startDate = $(".drp-selected:first").text().substring(0, 10)
            this.maxDate = this.getDateFormat(this.getDateFormat(moment(startDate).add(6, 'M')))
        },
        getDateFormat(dateObject) {
            return (!this.timePicker) ? moment(new Date(dateObject))/*.utcOffset('+0100')*/.format('YYYY-MM-DD') : moment(new Date(dateObject)).tz('Asia/Tokyo').format('YYYY-MM-DD')
        },

    }
}
</script>

<style lang="scss" scoped>
.separator {
    position: absolute;
    left: 46%;
}

.reportrange-text > span:first-child span:last-child {
    position: absolute !important;
    right: 15px !important;
}

.reportrange-text {
    min-width: 230px !important;
}
/*    #startDate{
        height: 40px;
        border-radius: 2px !important;
        border: 1px solid #BAB9B9 !important;
        width: 200px !important;
    }
    #endDate{
        height: 40px;
        border-radius: 2px !important;
        border: 1px solid #BAB9B9 !important;
        width: 200px !important;
    }*/
</style>
