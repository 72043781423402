var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"datepicker"},[_c('date-range-picker',{ref:"picker",attrs:{"autoApply":true,"linkedCalendars":true,"locale-data":{
            direction: 'ltr',
            format: 'yyyy/mm/dd',
            separator: ' - ',
            applyLabel: 'Apply',
            cancelLabel: 'Cancel',
            weekLabel: 'W',
            customRangeLabel: 'Custom Range',
            daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            monthNames: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
            firstDay: 0
        },"maxDate":_vm.maxDate,"opens":_vm.position,"ranges":false,"showDropdowns":true,"showWeekNumbers":true,"timePicker24Hour":false},on:{"update":_vm.resetDate,"change-month":_vm.limitDateRange},scopedSlots:_vm._u([{key:"input",fn:function(picker){return [_c('span',{staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(_vm._f("date")(picker.startDate)))]),_c('span',{staticClass:"separator"},[_vm._v("〜")]),_c('span',[_vm._v(_vm._s(_vm._f("date")(picker.endDate)))])])]}}]),model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }